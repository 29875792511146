import React from "react"
import { FaChevronCircleRight } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TheLadyBirdDeed = () => (
  <Layout>
    <SEO title="The &#8220;Lady Bird&#8221; Deed" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">
              The &#8220;Lady Bird&#8221; Deed
            </h1>
            <p>
              Many people own real property that they would like their family
              members to receive upon their death. One familiar method is to add
              the family members’ names onto the deed. This may seem like an
              easy way to avoid probate, but it is fraught with problems. A
              better approach is the &#8220;Lady Bird&#8221; Deed. The
              &#8220;Lady Bird Deed&#8221; is also called a &#8220;Transfer on
              Death Deed&#8221; or an &#8220;Enhanced Life Estate Deed&#8221;.
            </p>
            <h2 className="title is-2">How it works:</h2>
            <p>
              The owners ( &#8220;grantors&#8221;) deed the property to whomever
              they want,usually their children (&#8220;grantees&#8221;) but
              reserve for themselves a life estate coupled with the ability to
              sell the property at any time. This is called an &#8220;enhanced
              life estate&#8221;. In layman’s terms, this means that:
            </p>
            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                <span>The grantors still own the property;</span>
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                The grantors can sell, give away, or mortgage the property at
                any time;
              </li>
            </ul>
            <p>
              but if the grantors never dispose of the property, the house will
              pass directly to the grantees after the last grantor passes away.
            </p>
            <h2 className="title is-2">The Lady Bird deed has many virtues:</h2>
            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                It may avoid the &#8220;Estate Recovery&#8221; law, where the
                State of Michigan can make claims against assets of nursing home
                patients who start receiving Medicaid benefits after September
                30, 2007.
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                It does not result in capital gain problems. This is because
                they will not receive any value until the last grantor passes
                away. They take it at the stepped-up basis &#8211; not the
                grantors’ original basis. A &#8220;stepped-up&#8221; basis is
                the value of the property on the day of the last grantor’s
                death.
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                It does not open up the property to the grantees’ creditors
                during the grantors’ lifetimes, because the grantees have no
                interest until the last grantor has passed away, and only then
                if neither grantor disposed of the home. For example, if a
                grantee is sued during a grantor’s lifetime, no one can place a
                lien against the grantee’s interest in the grantor’s home,
                because the grantee has no present interest in the grantor’s
                home.
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                It allows the grantors to sell, give away, or mortgage their
                home at any time, versus a regular life estate where they would
                not be authorized to do so.
              </li>
            </ul>

            <p>
              Adding a name onto a deed as &#8220;Joint Tenants with Rights of
              Survivorship&#8221; (JTWROS) has been used for decades to avoid
              probate. However, this technique can create problems.
              Traditionally, JTWROS means that the last person alive receives
              100% of the property. If a sale of the property is desired,
              everyone on the deed has to agree to sell. One person can refuse
              to sell and the property will not be sold. In addition, it is
              considered a &#8220;gift&#8221; under the Medicaid program, which
              can result in months to years of no Medicaid benefits that would
              otherwise be available to patients in a nursing home.
            </p>
            <p>
              Finally, many accountants and lawyers believe and advise their
              clients that it creates capital gain for the grantees dating back
              to when the grantors signed the deed.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default TheLadyBirdDeed
